<template>
  <h5 class="q-ma-md text-center">{{ $t("my_wallet") }}</h5>
  <div class="q-pa-md flex row justify-center">
    <q-form class="q-gutter-md my-profile">
      <q-input v-model="nickname" :label="$t('nickname')" readonly />
      <q-input readonly v-model="rewards" :label="$t('cnftg_rewards')">
        <template v-slot:append>
          <q-icon name="redeem" @click="transfer" class="cursor-pointer" />
        </template>
      </q-input>
            <q-input readonly v-model="cnftg_balance" :label="$t('cnftg_balance')">
        <template v-slot:append>
          <q-icon name="send" @click="transfer" class="cursor-pointer" />
        </template>
      </q-input>
      <q-spacer />
      <q-spacer />
      
      <q-input
        readonly
        v-model="user.user_metadata.wallet"
        :label="$t('wallet_address')"
      >
        <template v-slot:append>
          <q-icon name="content_copy" @click="copy(user.user_metadata.wallet)" class="cursor-pointer" />
        </template>
      </q-input>
      <q-input
        readonly
        v-model="user.user_metadata.key"
        :label="$t('wallet_key')"
        @click="show_type = 'text'"
        :type="show_type"
      >
        <template v-slot:append>
          <q-icon name="content_copy" @click="copy(user.user_metadata.key)" class="cursor-pointer" />
        </template>
      </q-input>

      <q-spacer />
      <q-spacer />
      <q-input readonly v-model="balance" :label="$t('matic_balance')">
        <template v-slot:append>
          <q-icon name="send" @click="transfer" class="cursor-pointer" />
        </template>
      </q-input>

      <q-spacer />
      <q-spacer />
    </q-form>
  </div>

  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section>
        <div class="text-h6">Upgrade Confirm</div>
      </q-card-section>
      <q-card-section class="text-body"
        >You will upgrade to a Seller!</q-card-section
      >
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />
        <q-btn
          flat
          label="Upgrade"
          color="orange"
          v-close-popup
          @click="upgrade"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<style lang="sass" scoped>
.my-profile
  width: 100%
  max-width: 970px
</style>

<script>
import { ref, onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import { w3, APIURL } from "../web3.js";
import { useQuasar } from "quasar";
import axios from "axios";
import { copyToClipboard } from 'quasar'

export default {
  name: "MySettings",
  setup() {
    const user = s.auth.user();
    console.log(user);
    const myprofile = ref();
    const nickname = ref("");
    const balance = ref(0);
    const rewards = ref(0);
    const cnftg_balance = ref(0);

    w3.eth.getBalance(user.user_metadata.wallet).then((b) => {
      balance.value = w3.utils.fromWei(b);
    });
    const q = useQuasar();
    onBeforeMount(async () => {
      let { data: profile, error } = await s
        .from("profiles")
        .select("*")
        .eq("id", user.id);
      let p = profile[0];
      console.log(p);
      nickname.value = p.nickname;
      myprofile.value = p;
      rewards.value = p.balance;

      // call api get cnftg balance
      const cnftg_token_addr = "0x52a3fcb9d0a62eb507f3f9b4fa30d35ce03e6df8";
      const params = {
        acct: user.user_metadata.wallet,
        contract: cnftg_token_addr,
      };

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url: APIURL + "/i/b",
      };
      console.log(options);
      axios(options)
        .then(async (res) => {
          console.log(res);
          cnftg_balance.value = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    });

    return {
      show_type: ref("password"),
      cnftg_balance,
      myprofile,
      rewards,
      user,
      balance,
      nickname,
      q,
      confirm: ref(false),
      copyToClipboard
    };
  },
  methods: {
    copy(i) {
      copyToClipboard(i)
      this.q.notify("Copied! " + i)
    }
  },
};
</script>